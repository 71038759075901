import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    path: '/',
    alias: '/leagues',
    name: 'leagues-list',
    component: () => import('./components/LeaguesList')
  },
  {
    path: '/leagues/:leaguepk/seasons',
    name: 'league-seasons-list',
    component: () => import('./components/LeagueSeasonsList')
  },
  // 404 Catch all
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('./components/NotFound')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;