<template>
  <div id="app" class="container">
    <div class="row justify-content-center">
      <div class="col col-md-10 min-vh-100">
        <div class="row pb-3">
          <nav class="navbar navbar-expand-md navbar-light top-panel">
            <div class="container-fluid">
              <router-link to="/" class="pe-2 "><img src="/images/icon.png" class="logo-img" /></router-link>
              <router-link to="/" class="navbar-brand">Borakosa Football</router-link>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                  <li class="nav-item">
                    <router-link to="/leagues" class="nav-link">Leagues</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        <div class="row justify-content-center" style="min-height: 84%">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>

</style>
